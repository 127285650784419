exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */),
  "component---src-pages-setup-tsx": () => import("./../../../src/pages/setup.tsx" /* webpackChunkName: "component---src-pages-setup-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-rule-set-tsx": () => import("./../../../src/templates/rule-set.tsx" /* webpackChunkName: "component---src-templates-rule-set-tsx" */),
  "component---src-templates-setup-guide-tsx": () => import("./../../../src/templates/setup-guide.tsx" /* webpackChunkName: "component---src-templates-setup-guide-tsx" */)
}

